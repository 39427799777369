<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">签证审批统计台账</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active"
          >签证审批统计台账</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="card-box">
      <div class="screen-box">
        <div class="screen-input-box">
          <div class="title-box">签证类型:</div>
          <el-select
            v-model="visaType"
            placeholder="签证类型"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in visaTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">sponsor:</div>
          <el-select
            v-model="sponsor"
            placeholder="sponsor"
            filterable
            clearable
            class="card-select"
          >
            <el-option
              v-for="item in sponsorList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="screen-input-box">
          <div class="title-box">审批同意日期:</div>
          <el-date-picker
            v-model="evacuationDate"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <button class="btn-pre" @click="getEmployeeVisaHistories(1)">
          <i class="fa fa-search"></i>查询
        </button>
        <button class="btn-pre" @click="exportEmployeeVisaHistory">
          <i class="fa fa-download"></i>导出
        </button>
      </div>
      <div class="table-box">
        <el-table
          :data="tableData"
          height="500"
          border
          tooltip-effect="light"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
          :empty-text="loading ? '加载中...' : '暂无数据'"
        >
          <el-table-column label="#" width="55">
            <template slot-scope="scope">
              <el-checkbox></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column type="index" label="序号" width="50" />
          <el-table-column
            prop="Main.ApplicationNumber"
            label="表单编号"
            width="125"
          />
          <el-table-column
            prop="Main.ProjectName"
            label="项目名称"
            width="70"
          />
          <el-table-column
            prop="Main.SubProjectName"
            label="分项目名称"
            width="100"
          >
          </el-table-column>
          <el-table-column
            prop="Main.WorkingUnit"
            label="用工单位"
            width="70"
          />
          <el-table-column prop="DateofBirth" label="出生日期" width="80">
            <template slot-scope="scope">
              {{ scope.row.DateofBirth | tableDateFrom }}</template
            >
          </el-table-column>
          <el-table-column prop="Main.VisaType" label="签证类型" width="65">
            <template slot-scope="scope">
              {{ scope.row.Main.VisaType | valueFrom(visaTypeList) }}</template
            >
          </el-table-column>
          <el-table-column prop="Main.Sponsor" label="新办签证" width="65">
            <template slot-scope="scope">
              {{ scope.row.Main.Sponsor | valueFrom(sponsorList) }}</template
            >
          </el-table-column>
          <el-table-column prop="EmployeeName" label="中文名" width="60" />
          <el-table-column prop="EmployeeNameEN" label="英文名" width="110" />
          <el-table-column prop="PassportCode" label="护照号" width="85" />

          <el-table-column
            prop="IDofHomeCountry"
            label="身份证号"
            width="125"
          />
          <el-table-column prop="Main.ApproveDate" label="审批日期" width="80">
            <template slot-scope="scope">
              {{ scope.row.Main.ApproveDate | tableDateFrom }}</template
            >
          </el-table-column>
        </el-table>

        <el-pagination
          @size-change="getEmployeeVisaHistories(1)"
          @current-change="getEmployeeVisaHistories"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="签证审批统计台账"
      :visible.sync="dialogVisible"
      width="99vw"
      :before-close="handleClose"
      top="1vh"
    >
      <div class="dialog-main">
        <SLedgerTable
          ref="approvalTable"
          @updata="getEmployeeVisaHistories"
          @close="handleClose"
        ></SLedgerTable>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getEmployeeVisaHistories,
  exportEmployeeVisaHistory,
} from "@/api/table";
import SLedgerTable from "@/components/tables/SLedgerTable.vue";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    valueFrom(id, data) {
      const item = data.find((item) => {
        return item.value === id;
      });
      return item ? item.label : "";
    },
  },
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      page: 1,
      size: 10,
      total: 10,
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },
      EditState: 1,
      disabled: false,
      loading: true,
      visaType: null,
      sponsor: null,
      evacuationDate: null,
      visaTypeList: [
        {
          value: 1,
          label: "工作",
        },
        {
          value: 2,
          label: "工访",
        },
        {
          value: 3,
          label: "商访",
        },
        {
          value: 4,
          label: "家访",
        },
      ],
      sponsorList: [
        {
          label: "SNEI",
          value: 1,
        },
        {
          label: "SEG",
          value: 2,
        },
        {
          label: "其它",
          value: 3,
        },
      ],
    };
  },
  components: { SLedgerTable },
  methods: {
    handleClose() {
      this.dialogVisible = false;
      this.EditState = 1;
      this.disabled = false;
      this.$refs.approvalTable.clearData();
    },
    query(data) {
      this.dialogVisible = true;
      this.disabled = true;
      this.EditState = data.EditState;
      this.getManpowerSupplierItem(data.KeyID);
    },

    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }

      const item = this.$store.state.userPower.find((item) => {
        return item.Name === this.$route.meta.title;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
    //获取表格
    getEmployeeVisaHistories(type) {
      if (type) {
        this.page = type;
      }
      this.loading = true;
      let approveStartDate = null;
      let approveEndDate = null;
      if (this.evacuationDate) {
        approveStartDate = this.evacuationDate[0];
        approveEndDate = this.evacuationDate[1];
      }
      const data = {
        page: this.page,
        size: this.size,
        visaType: this.visaType,
        sponsor: this.sponsor,
        approveStartDate: approveStartDate,
        approveEndDate: approveEndDate,
      };
      getEmployeeVisaHistories(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          console.log("this.tableData", this.tableData);
          this.total = res.response.dataCount;
        }
        this.loading = false;
      });
    },

    exportEmployeeVisaHistory() {
      let approveStartDate = null;
      let approveEndDate = null;
      if (this.evacuationDate) {
        approveStartDate = this.evacuationDate[0];
        approveEndDate = this.evacuationDate[1];
      }
      const data = {
        page: this.page,
        size: this.size,
        visaType: this.visaType,
        sponsor: this.sponsor,
        approveStartDate: approveStartDate,
        approveEndDate: approveEndDate,
      };
      if (this.evacuationDate) {
        data.requestStartDate = this.evacuationDate[0];
        data.requestEndDate = this.evacuationDate[1];
      }
      exportEmployeeVisaHistory(data).then((res) => {
        if (res.status === 200) {
          let url = res.response;
          let charIndex = url.indexOf("Download");
          url = url.substr(charIndex, url.length);
          url = url.replace(/\\/g, "/");
          location.href = "https://hrms.baishulai.com/" + url;
        }
      });
    },
  },

  created() {
    this.getButtonPower();
    this.getEmployeeVisaHistories();
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 0 0 24px 0;
    .screen-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 0 10px;
      .screen-input-box {
        margin-top: 24px;
      }
      .btn-pre {
        margin-top: 24px;
      }
      .btn-add {
        margin-top: 24px;
      }
      .el-input {
        width: 150px;
        margin-right: 24px;
      }
      .el-button {
        margin-right: 24px;
      }
      .el-select {
        width: 150px;
        margin-right: 24px;
      }
      .el-date-editor {
        margin-right: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 70px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
      .fa {
        margin-right: 10px;
        color: #3c8dbc;
        cursor: pointer;
      }
      .fa:hover {
        color: #72afd2;
      }
    }
  }
  .dialog-main {
    border-bottom: 1px solid #f4f4f4;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
    .line-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .from-box {
        display: flex;
        align-items: center;
        width: 330px;
        margin-bottom: 24px;
        .name {
          width: 80px;
          font-size: 16px;
          flex-shrink: 0;
        }
        .el-cascader {
          width: 250px;
        }
        .el-input {
          width: 250px;
        }
      }
    }
  }
  .dialog-footer {
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .el-button {
      height: 34px;
      padding: 0 20px;
      margin-right: 12px;
      margin-left: 0;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
.upload-box {
  display: flex;
  height: 34px;
  margin-top: 24px;
  .left {
    width: 100%;
    border: 1px solid #d2d6de;
    border-right: none;
    .name {
      display: flex;
      align-items: center;
      height: 34px;
      color: #555;
      font-size: 14px;
      text-indent: 2px;
    }
  }
  .btn-up {
    padding: 0;
    margin: 0;
    border: none;
    width: 90px;
    flex-shrink: 0;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #3c8dbc;
    border: 1px solid #367fa9;
    color: #fff;
    font-size: 14px;
    .icon {
      margin-right: 5px;
    }
  }
  .disabled {
    cursor: no-drop;
  }
  .btn-up:hover {
    border-color: #204d74;
    background-color: #367fa9;
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
.tableData2 >>> .el-table__empty-block {
  width: 100% !important;
}
.card-select >>> .el-input__icon {
  line-height: 34px;
}
</style>
